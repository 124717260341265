import UtilsEntity from "./entity";

export default class UtilsEntityView {
    static edit(id: any, entity: string) {
        let url = `/${entity.replace("_", "/")}/${id}/edit`
        return UtilsEntityView.request(url, 'GET')
    }

    static async request(url: string, method: string, body = {}) {
        const init = {
            method: method,
            headers: {
                'Accept': 'text/html',
                'Content-Type': 'text/html'
            }
        }
        const r = await fetch(`${url}?partial=true`, init)
        return await r.text()
    }
}